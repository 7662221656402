<template>
  <div class="match">
    <span class="match-area-content">
      
      <!-- Play Match content time and add point -->
      <div v-if="!isMatchResult" class="text-center"  :class="{'match-header': true }">
        <div class="match-time">
          {{ moment(match.matchStart).format("HH:mm") }}
        </div>
        <div class="match-points d-flex justify-content-center">
          <div class="match-points-label mx-2">
            {{ $t("general.points") + matchPoints }}
          </div>
          <el-button
            v-if="matchDay.matches.filter((m) => m.bets.length < 1).length == matchDay.matches.length || matchDay.matches.filter((m) => m.editing).length > 0"
            @click="addPoints"
            class="add-points"
            type="primary"
            icon="el-icon-plus">
          </el-button>
        </div>
      </div>
      
      
      
      <div class="teams" v-if="!isMatchResult">
        <!-- First Team -->
        <div @click="toggleBet('homeTeam')" class="home-option">
          <img width="32" heigth="32" :src="loadFlag.home" @load="changeFlag('home', match.homeTeam.flag)"/>
          <div
            :class="{
              'team': true,
              'play-match-team': true,
              'selected-choice': !isMatchResult && match.selected.includes(match.homeTeam.id),
              'locked-choice': !isMatchResult && ((!match.secondChoice && match.selected.length > 0) || (match.secondChoice && match.selected.length > 1) || (match.bets.length > 0 && !match.bets[0].homeWin)),
              'locked-for-edit': !isMatchResult && !match.selected.includes(match.homeTeam.id) && !inEditionMode
            }">
            <span>{{ match.homeTeam["name_" + language]}}</span>
          </div>
        </div>

        <div @click="toggleBet('draw')" class="draw-option">
          <div
            :class="{
              'team': true,
              'draw-size-increase': isMatchResult,
              'play-match-team': true,
              'selected-choice': !isMatchResult && match.selected.includes('draw'),
              'locked-choice': !isMatchResult && (match.decisive || (!match.secondChoice && match.selected.length > 0) || (match.secondChoice && match.selected.length > 1) || (match.bets.length > 0 && !match.bets[0].draw)),
              'decisive-match': !isMatchResult && match.decisive,
              'locked-for-edit': !isMatchResult && !match.selected.includes('draw') && !inEditionMode
            }">
            <span>{{ !isMatchResult ? $t("playMatch.draw") : (match.homeScore || 0) + ' : ' + (match.awayScore || 0) }}</span>
          </div>
        </div>

        <div @click="toggleBet('awayTeam')" class="away-option">
          <img width="32" heigth="32" :src="loadFlag.away" @load="changeFlag('away', match.awayTeam.flag)"/>
          <div
            :class="{
              'team': true,
              'play-match-team': true,
              'selected-choice': !isMatchResult && match.selected.includes(match.awayTeam.id),
              'locked-choice': !isMatchResult && ((!match.secondChoice && match.selected.length > 0) || (match.secondChoice && match.selected.length > 1) || (match.bets.length > 0 && !match.bets[0].awayWin)),
              'locked-for-edit': !isMatchResult && !match.selected.includes(match.awayTeam.id) && !inEditionMode
            }">
            <span>{{ match.awayTeam["name_" + language] }}</span>
          </div>
        </div>
      </div>
      
      
      <!-- is match ended -->
      <div class="teams_end_match" v-else>
        <!-- First Team -->
        <div @click="toggleBet('homeTeam')" class="home-option">
          <img width="32" heigth="32" :src="loadFlag.home" @load="changeFlag('home', match.homeTeam.flag)"/>
          <div
            :class="{
              'team': true,
              'play-match-team': true,
              'selected-choice': !isMatchResult && match.selected.includes(match.homeTeam.id),
              'locked-choice': !isMatchResult && ((!match.secondChoice && match.selected.length > 0) || (match.secondChoice && match.selected.length > 1) || (match.bets.length > 0 && !match.bets[0].homeWin)),
              'locked-for-edit': !isMatchResult && !match.selected.includes(match.homeTeam.id) && !inEditionMode
            }">
            <span>{{ match.homeTeam["name_" + language]}}</span>
          </div>
        </div>

        <div @click="toggleBet('draw')" class="draw-option">
          <div
            :class="{
              'team': true,
              'draw-size-increase': isMatchResult,
              'play-match-team': true,
              'selected-choice': !isMatchResult && match.selected.includes('draw'),
              'locked-choice': !isMatchResult && (match.decisive || (!match.secondChoice && match.selected.length > 0) || (match.secondChoice && match.selected.length > 1) || (match.bets.length > 0 && !match.bets[0].draw)),
              'decisive-match': !isMatchResult && match.decisive,
              'locked-for-edit': !isMatchResult && !match.selected.includes('draw') && !inEditionMode
            }">
            <span>{{ !isMatchResult ? $t("playMatch.draw") : (match.homeScore || 0) + ' : ' + (match.awayScore || 0) }}</span>
          </div>
        </div>

        <div @click="toggleBet('awayTeam')" class="away-option">
          <img width="32" heigth="32" :src="loadFlag.away" @load="changeFlag('away', match.awayTeam.flag)"/>
          <div
            :class="{
              'team': true,
              'play-match-team': true,
              'selected-choice': !isMatchResult && match.selected.includes(match.awayTeam.id),
              'locked-choice': !isMatchResult && ((!match.secondChoice && match.selected.length > 0) || (match.secondChoice && match.selected.length > 1) || (match.bets.length > 0 && !match.bets[0].awayWin)),
              'locked-for-edit': !isMatchResult && !match.selected.includes(match.awayTeam.id) && !inEditionMode
            }">
            <span>{{ match.awayTeam["name_" + language] }}</span>
          </div>
        </div>
      </div>
      
      
      
      
      
      <ModalPoints v-if="showPointsDialog" @closeDialog="onCloseDialog" @setPoints="onSetPoints" :points="matchPoints" :minPoints="match.minPoints"></ModalPoints>
    </span>
    <!-- <div class="match-separator"></div> -->
  </div>
</template>

<script>
import i18n from "@/translation";
import moment from "moment";
import ModalPoints from "./ModalPoints.vue";

export default {
  name: "MatchComponent",
  props: ["matchDay", "match", "index", "minPoints", "isMatchResult"],
  components: {
    ModalPoints
  },
  data() {
    return {
      showPointsDialog: false,
      loadFlag: {'home': require('@/assets/load-flag.png'), 'away': require('@/assets/load-flag.png') }
    };
  },
  methods: {
    moment: function (data) {
      return moment(data);
    },

    addPoints() {
      this.showPointsDialog = true;
    },

    onSetPoints(points) {
      this.matchPoints = points;
    },
    onCloseDialog(){
      this.showPointsDialog = false
    },
    changeFlag(type, src){
      this.loadFlag[type] = src 
    },
    isTeamInBets(team){
      return this.match.preBets.length > 0 && ((team == 'homeTeam' && this.match.preBets[0].homeWin) || (team == 'draw' && this.match.preBets[0].draw) || (team == 'awayTeam' && this.match.preBets[0].awayWin))
    },

    toggleBet(team) {
      let team_id = 'draw'
      if(team == 'awayTeam')
        team_id = this.match.awayTeam.id
      if(team == 'homeTeam')
        team_id = this.match.homeTeam.id

      if(!this.inEditionMode || this.isMatchResult){
        return;
      }

      if((this.match.decisive && team_id == 'draw') || this.match.bets.length > 0 || this.isTeamInBets(team)){
        return;
      }
        
      if ((this.match.secondChoice || this.match.selected.length == 0) && !this.match.selected.includes(team_id) && this.match.selected.length < 2) {
        this.$emit("setMatchSelected", this.match, team_id);
        if (this.match.selected.length > 1) {
          let value = this.matchDay.currentCoins - 2;
          this.$emit("setMatchDayCurrentCoins", this.matchDay, value);
        }
      } else if (this.match.selected.includes(team_id)) {
        this.$emit("setMatchSelected", this.match, team_id);
        if (this.match.selected.length == 1) {
          let value = this.matchDay.currentCoins == 0  ? 0 : this.matchDay.currentCoins + 2;
          this.$emit("setMatchDayCurrentCoins", this.matchDay, value);
        }
      }
    },
    setSecondChoice() {
      if (!this.match.secondChoice) {
        this.$emit("setSecondChoice", this.match);
      }
    },
    getResultsScore(team){
      if(team == 'home'){
        return (this.match.homeScore ? this.match.homeScore : 0) + (this.match.decisive && this.match.penalties ? ' (' + this.match.penalties.homeScore + ')' : '')
      }
      else {
        return (this.match.awayScore ? this.match.awayScore : 0) + (this.match.decisive && this.match.penalties ? ' (' + this.match.penalties.awayScore + ')' : '')
      }
    }
  },
  watch: {
    matchPoints(newValue, oldValue) {
      let value = this.matchDay.currentCoins + (oldValue < newValue ? -1 : 1);
      this.$emit("setMatchDayCurrentCoins", this.matchDay, value);
    },
    match(){
      this.loadFlag.away = this.loadFlag.home = require('@/assets/load-flag.png')
    }
  },
  computed: {
    matchPoints: {
      get: function () {
        return this.match.points;
      },
      set: function (value) {
        this.$emit("setMatchPoints", this.match, value);
      },
    },
    inEditionMode() {
      return this.matchDay.matches.filter((m) => m.bets.length < 1).length == this.matchDay.matches.length || this.matchDay.matches.filter((m) => m.editing).length > 0
    },
    language() {
      return i18n.locale;
    },
  },
};
</script>

<style scoped>
.match-area-content {
  display: block;
  padding: 20px 5px 20px 5px;
  /* border-bottom: 1px solid #a69213; */
  background: #101A41 ;
  margin: 10px !important;
  border-radius: 15px !important;
  
}

.match-header {

}

.match-header-top {
  margin-top: 0px;
}

.results-match-header {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  display: flex;
  align-content: center;
  justify-content: flex-end;
  padding: 1px 5px 0px 10px;
  margin-top: 10px;
}

.add-points {
  padding: 2px;
    border-color: #ffffff !important;
    margin-right: 25px;
    color: #0C1538;
    background: transparent !important;
    border: 0px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 7px;
}

:deep(i.el-icon-plus) {
  font-weight: var(--appFontWeight) !important;
  color: #000000;
  background: linear-gradient(#F0C6AC,#C5B9B3) !important;
  border: 0px;
  border-radius: 12px;
  padding: 3px;
  text-transform: uppercase;
}

.match-time {
  font-size: 12px;
  color: #ffffff;
}



.match-points {
  font-size: 14px;
}


.match-points-label {
  font-size: 12px;
  color: #ffffff;
}

.match-result {
  padding: 2px 0px 2px 5px !important;
}

.match-separator {
  height: 10px;
  background: none;
  margin: 8px 0;
}

.match-tp-separator {
  background-color: #aaff01;
  height: 1.5px;
}



.results-match-points {
  padding: 2px 1px;
  font-size: 14px;
}



.versus {
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-block;
  color: rgb(0, 0, 0);

  text-transform: uppercase;
  font-size: 14px;
}

.second-choice {
  line-height: 15px;
  white-space: nowrap;
  cursor: pointer;
  color: #000000;
  background-color: #aaff01;
  text-align: center;
  margin: 0;
  font-size: 15px;
  padding: 7px 20px;
  box-shadow: #000000 5px;
}

.second-choice-selected {
  color: #444 !important;
  background-color: #a0e418;
}

.locked-choice, .locked-for-edit {
  background: #FFFFFF !important;
  border-color: #FFFFFF !important;
  color: #000 !important;
}

.decisive-match {
  visibility: hidden;
} 

.teams {
  display: flex;
  justify-content: space-evenly;
  padding: 0px 2px;
  padding-top: 0px;
  /* align-items: center !important; */
}


.teams .home-option, .away-option {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

}
.teams .draw-option {
  align-self: flex-end;
}

.teams .draw-size-increase span{
  font-size: 18px !important;
}

.teams .home-option img, .away-option img {
  margin-bottom: 5px;
}

.teams-results {
  padding: 15px 2px 0px 2px !important;
}

.teams_end_match {
  display: flex;
  justify-content: space-evenly;
  padding: 0px 2px;
  padding-top: 0px;
  /* align-items: center !important; */
}

.teams_end_match .home-option,.teams_end_match .away-option {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row !important;
}

.teams_end_match .home-option img, .teams_end_match .away-option img {
  margin:0px 6px !important;
}


.teams_end_match .home-option span, .teams_end_match .away-option span {
  color: white !important;
  font-size: 14px !important;
}


.teams_end_match .draw-option {
  align-self: center !important;
  color: white !important;
  font-size: 20px !important;
}

.selected-choice {
  background: #51607D !important;
  border: none !important;

  color: #fff !important;
}

.result-choice-correct {
  background: #51607D !important;
  border-color: #51607D !important;
  color: #fff !important;
}

.result-choice-wrong {
  background: #ff0000 !important;
  border-color: #ff0000 !important;
  color: #fff !important;
}

.result-awaiting-winner-choice {
  background: orange !important;
  border-color: orange !important;
  color: #fff !important;
}

.teams .team {
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  margin: 0;
  font-size: 13px;
  border-radius: 10px;
  position: relative;
  min-width: 100px;
  padding: 2px 3px;
  background: #FFFFFF;
}

.teams .play-match-team {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #0C1538;
  border-color: rgb(255, 255, 255);
  margin-top: 15px !important;
  outline: none;
  border: none;
}

.play-match-team span {

}

.result-team {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 105px;
  color: rgb(105, 105, 105);
  background: #eee;
  border-color: #eee;
  border-radius: 5px;
}

.results-score {
  font-size: 16px;
  padding-top: 5px;
  color: rgb(105, 105, 105);
}

.results-match-points {
  display: flex;
  justify-content: space-around;
}

</style>
