var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"match"},[_c('span',{staticClass:"match-area-content"},[(!_vm.isMatchResult)?_c('div',{staticClass:"text-center",class:{'match-header': true }},[_c('div',{staticClass:"match-time"},[_vm._v(" "+_vm._s(_vm.moment(_vm.match.matchStart).format("HH:mm"))+" ")]),_c('div',{staticClass:"match-points d-flex justify-content-center"},[_c('div',{staticClass:"match-points-label mx-2"},[_vm._v(" "+_vm._s(_vm.$t("general.points") + _vm.matchPoints)+" ")]),(_vm.matchDay.matches.filter((m) => m.bets.length < 1).length == _vm.matchDay.matches.length || _vm.matchDay.matches.filter((m) => m.editing).length > 0)?_c('el-button',{staticClass:"add-points",attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":_vm.addPoints}}):_vm._e()],1)]):_vm._e(),(!_vm.isMatchResult)?_c('div',{staticClass:"teams"},[_c('div',{staticClass:"home-option",on:{"click":function($event){return _vm.toggleBet('homeTeam')}}},[_c('img',{attrs:{"width":"32","heigth":"32","src":_vm.loadFlag.home},on:{"load":function($event){return _vm.changeFlag('home', _vm.match.homeTeam.flag)}}}),_c('div',{class:{
            'team': true,
            'play-match-team': true,
            'selected-choice': !_vm.isMatchResult && _vm.match.selected.includes(_vm.match.homeTeam.id),
            'locked-choice': !_vm.isMatchResult && ((!_vm.match.secondChoice && _vm.match.selected.length > 0) || (_vm.match.secondChoice && _vm.match.selected.length > 1) || (_vm.match.bets.length > 0 && !_vm.match.bets[0].homeWin)),
            'locked-for-edit': !_vm.isMatchResult && !_vm.match.selected.includes(_vm.match.homeTeam.id) && !_vm.inEditionMode
          }},[_c('span',[_vm._v(_vm._s(_vm.match.homeTeam["name_" + _vm.language]))])])]),_c('div',{staticClass:"draw-option",on:{"click":function($event){return _vm.toggleBet('draw')}}},[_c('div',{class:{
            'team': true,
            'draw-size-increase': _vm.isMatchResult,
            'play-match-team': true,
            'selected-choice': !_vm.isMatchResult && _vm.match.selected.includes('draw'),
            'locked-choice': !_vm.isMatchResult && (_vm.match.decisive || (!_vm.match.secondChoice && _vm.match.selected.length > 0) || (_vm.match.secondChoice && _vm.match.selected.length > 1) || (_vm.match.bets.length > 0 && !_vm.match.bets[0].draw)),
            'decisive-match': !_vm.isMatchResult && _vm.match.decisive,
            'locked-for-edit': !_vm.isMatchResult && !_vm.match.selected.includes('draw') && !_vm.inEditionMode
          }},[_c('span',[_vm._v(_vm._s(!_vm.isMatchResult ? _vm.$t("playMatch.draw") : (_vm.match.homeScore || 0) + ' : ' + (_vm.match.awayScore || 0)))])])]),_c('div',{staticClass:"away-option",on:{"click":function($event){return _vm.toggleBet('awayTeam')}}},[_c('img',{attrs:{"width":"32","heigth":"32","src":_vm.loadFlag.away},on:{"load":function($event){return _vm.changeFlag('away', _vm.match.awayTeam.flag)}}}),_c('div',{class:{
            'team': true,
            'play-match-team': true,
            'selected-choice': !_vm.isMatchResult && _vm.match.selected.includes(_vm.match.awayTeam.id),
            'locked-choice': !_vm.isMatchResult && ((!_vm.match.secondChoice && _vm.match.selected.length > 0) || (_vm.match.secondChoice && _vm.match.selected.length > 1) || (_vm.match.bets.length > 0 && !_vm.match.bets[0].awayWin)),
            'locked-for-edit': !_vm.isMatchResult && !_vm.match.selected.includes(_vm.match.awayTeam.id) && !_vm.inEditionMode
          }},[_c('span',[_vm._v(_vm._s(_vm.match.awayTeam["name_" + _vm.language]))])])])]):_c('div',{staticClass:"teams_end_match"},[_c('div',{staticClass:"home-option",on:{"click":function($event){return _vm.toggleBet('homeTeam')}}},[_c('img',{attrs:{"width":"32","heigth":"32","src":_vm.loadFlag.home},on:{"load":function($event){return _vm.changeFlag('home', _vm.match.homeTeam.flag)}}}),_c('div',{class:{
            'team': true,
            'play-match-team': true,
            'selected-choice': !_vm.isMatchResult && _vm.match.selected.includes(_vm.match.homeTeam.id),
            'locked-choice': !_vm.isMatchResult && ((!_vm.match.secondChoice && _vm.match.selected.length > 0) || (_vm.match.secondChoice && _vm.match.selected.length > 1) || (_vm.match.bets.length > 0 && !_vm.match.bets[0].homeWin)),
            'locked-for-edit': !_vm.isMatchResult && !_vm.match.selected.includes(_vm.match.homeTeam.id) && !_vm.inEditionMode
          }},[_c('span',[_vm._v(_vm._s(_vm.match.homeTeam["name_" + _vm.language]))])])]),_c('div',{staticClass:"draw-option",on:{"click":function($event){return _vm.toggleBet('draw')}}},[_c('div',{class:{
            'team': true,
            'draw-size-increase': _vm.isMatchResult,
            'play-match-team': true,
            'selected-choice': !_vm.isMatchResult && _vm.match.selected.includes('draw'),
            'locked-choice': !_vm.isMatchResult && (_vm.match.decisive || (!_vm.match.secondChoice && _vm.match.selected.length > 0) || (_vm.match.secondChoice && _vm.match.selected.length > 1) || (_vm.match.bets.length > 0 && !_vm.match.bets[0].draw)),
            'decisive-match': !_vm.isMatchResult && _vm.match.decisive,
            'locked-for-edit': !_vm.isMatchResult && !_vm.match.selected.includes('draw') && !_vm.inEditionMode
          }},[_c('span',[_vm._v(_vm._s(!_vm.isMatchResult ? _vm.$t("playMatch.draw") : (_vm.match.homeScore || 0) + ' : ' + (_vm.match.awayScore || 0)))])])]),_c('div',{staticClass:"away-option",on:{"click":function($event){return _vm.toggleBet('awayTeam')}}},[_c('img',{attrs:{"width":"32","heigth":"32","src":_vm.loadFlag.away},on:{"load":function($event){return _vm.changeFlag('away', _vm.match.awayTeam.flag)}}}),_c('div',{class:{
            'team': true,
            'play-match-team': true,
            'selected-choice': !_vm.isMatchResult && _vm.match.selected.includes(_vm.match.awayTeam.id),
            'locked-choice': !_vm.isMatchResult && ((!_vm.match.secondChoice && _vm.match.selected.length > 0) || (_vm.match.secondChoice && _vm.match.selected.length > 1) || (_vm.match.bets.length > 0 && !_vm.match.bets[0].awayWin)),
            'locked-for-edit': !_vm.isMatchResult && !_vm.match.selected.includes(_vm.match.awayTeam.id) && !_vm.inEditionMode
          }},[_c('span',[_vm._v(_vm._s(_vm.match.awayTeam["name_" + _vm.language]))])])])]),(_vm.showPointsDialog)?_c('ModalPoints',{attrs:{"points":_vm.matchPoints,"minPoints":_vm.match.minPoints},on:{"closeDialog":_vm.onCloseDialog,"setPoints":_vm.onSetPoints}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }